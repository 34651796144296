<template>
  <v-app-bar v-if="!isMobile" style="width: 100%; height: 64px" id="header-navbar" app :color="navbar_color" light>
    <v-img
      :src="logo"
      to="/dashboard/temperatures"
      @click="reloadDevices"
      class="my-3"
      contain
      :width="width_logo"
      id="btn-logo" />
    <span style="margin-right: 1rem; z-index: 1" @click="reloadDevices" class="cursor version-site version-site-desktop">
      {{ release }}</span
    >
    <p class="xsSize"></p>
    <v-tabs style="width: 1350px" id="btn-navbar">
      <v-tab style="width: 14.28%" data-cy="temperatures" to="/dashboard/temperatures" class="gradient" @click="temperatureView">
        <font-awesome-icon class="icon-navbar icon-navbar-thermometer" icon="fa-solid fa-temperature-empty" />
        <span class="text-nav-bar-thermometer">{{ $t('nav_bar.thermography') }}</span>
      </v-tab>
      <v-tab style="width: 14.28%" data-cy="map" class="gradient" @click="mapView">
        <font-awesome-icon class="icon-navbar icon-navbar-map" icon="fa-map-location-dot" />
        <span class="text-nav-bar-map">{{ $t('nav_bar.map') }}</span>
      </v-tab>
      <v-tab style="width: 14.28%" data-cy="reports" v-if="!isUserClient" to="/reports">
        <font-awesome-icon :color="text_color" icon="fa-solid fa-file-contract" class="icon-navbar" />
        <span class="text-nav-bar" :color="text_color">{{ $t('nav_bar.reports') }}</span>
      </v-tab>

      <v-tab style="width: 14.28%" data-cy="shared" v-if="!isUserClient" to="/shared">
        <font-awesome-icon :color="text_color" class="icon-navbar" icon="fa-solid fa-share-nodes" />
        <span class="text-nav-bar">{{ $t('nav_bar.client') }}</span>
      </v-tab>
      <v-tab style="width: 14.28%" data-cy="admin" v-if="isUserAdmin" to="/admin">
        <font-awesome-icon :color="text_color" class="icon-navbar" icon="fa-solid fa-user-shield" />
        <span class="text-nav-bar">{{ $t('nav_bar.admin') }}</span>
      </v-tab>

      <v-tab style="width: 15.28%" data-cy="docs" v-if="!isUserClient" to="/maintenance/certs">
        <!-- <font-awesome-icon :color="text_color" class="icon-navbar" icon="fa fa-book" /> -->
        <font-awesome-icon color="text_color" class="icon-navbar" :icon="['fas', 'screwdriver-wrench']" />
        <!-- <v-icon>mdi-wrench</v-icon> -->
        <span class="text-nav-bar">{{ $t('nav_bar.maintenance') }}</span>
      </v-tab>

      <v-tab style="width: 18.28%" id="btn-language" class="d-flex align-center" data-cy="change_lenguage">
        <v-menu open-on-hover>
          <template v-slot:activator="{ on, attrs }">
            <v-list-item v-bind="attrs" v-on="on" slot="activator">
              <country-flag style="height: 2.59rem" :country="lang"></country-flag>
            </v-list-item>
          </template>
          <v-list>
            <v-list-item v-for="language in languages" @click="changeLanguage(language.code)" v-bind:key="language.code">
              <v-list-item-avatar>
                <country-flag :country="language.icon_code"></country-flag>
              </v-list-item-avatar>

              <v-list-item-title>{{ language.name }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-tab>
    </v-tabs>

    <div id="btn-change-light" class="d-flex">
      <div class="light" data-cy="light">
        <v-btn @click="changeTheme('light')" small>
          <v-icon fab x-small>mdi-white-balance-sunny</v-icon>
        </v-btn>
      </div>
      <div class="dark" data-cy="dark">
        <v-btn @click="changeTheme('dark')" small>
          <v-icon fab x-small>mdi-weather-night</v-icon>
        </v-btn>
      </div>
    </div>
    <v-menu v-model="menu" bottom right transition="scale-transition" origin="top left">
      <template v-slot:activator="{ on, attrs }">
        <i v-bind="attrs" v-on="on" style="margin-left: 25px" icon>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on" :color="theme === 'dark' ? 'white' : 'rgba(0, 0, 0, 0.54)'" light>
                mdi-phone-message
              </v-icon>
            </template>
            <span>{{ $t('user.contact.chat_with_us') }}</span>
          </v-tooltip>
        </i>
      </template>
      <v-card width="300">
        <v-list dark>
          <v-list-item>
            <!-- *CAMBIAR POR LINK DE AMAZON(cuando la tengamos en amazon)-->
            <v-list-item-avatar>
              <v-img src="../../../public/ApacheFavicon.png"></v-img>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>{{ $t('user.contact.technical_support') }}</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn icon @click="menu = false">
                <v-icon>mdi-close-circle</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-list>
        <v-list>
          <v-list-item>
            <v-list-item-action>
              <v-icon :color="theme === 'dark' ? 'white' : 'rgba(0, 0, 0, 0.54)'" light>mdi-whatsapp</v-icon>
            </v-list-item-action>
            <v-list-item-subtitle>
              <a :href="waPhone"> {{ phoneSupport }}</a>
            </v-list-item-subtitle>
          </v-list-item>
          <v-list-item>
            <v-list-item-action>
              <v-icon :color="theme === 'dark' ? 'white' : 'rgba(0, 0, 0, 0.54)'" light>mdi-email-fast</v-icon>
            </v-list-item-action>
            <v-list-item-subtitle>
              <a :href="`mailto:${emailSupport}`">{{ emailSupport }}</a>
            </v-list-item-subtitle>
          </v-list-item>
          <v-list-item>
            <v-list-item-action>
              <v-icon :dark="theme === 'dark'" class="">mdi-download</v-icon>
            </v-list-item-action>
            <v-list-item-subtitle>
              <v-list-item :href="manual" target="_blank">
                <v-list-item-title>{{ $t('navbar.download_manual') }}</v-list-item-title>
              </v-list-item>
            </v-list-item-subtitle>
          </v-list-item>
        </v-list>
      </v-card>
    </v-menu>

    <v-container style="width: 20.5rem" id="btn-account">
      <v-row class="btn-account-positicion">
        <div class="btn-account-contain d-flex align-center">
          <div class="menuNav" style="padding-right: 8px">
            <v-menu open-on-hover offset-y>
              <template class="template-nav" v-slot:activator="{ on, attrs }">
                <v-btn class="btn-user" light icon v-bind="attrs" v-on="on">
                  <v-avatar v-if="resImage" size="40">
                    <v-img :src="resImage"></v-img>
                  </v-avatar>
                  <v-avatar v-else size="25">
                    <font-awesome-icon class="user.img" icon="fa-solid fa-user" />
                  </v-avatar>
                  <div v-if="!isUserAdmin">
                    <div class="user-name-not-admin">{{ username }}</div>
                  </div>
                  <v-icon class="user-text">mdi-chevron-down</v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item v-if="isUserAdmin" class="user-content">
                  <div class="user-name">{{ username }}</div>
                </v-list-item>
                <v-list-item @click="configurationView">
                  <v-list-item-avatar>
                    <v-icon class="user-img">mdi-cog</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-title>{{ $t('nav_bar.menu.settings') }}</v-list-item-title>
                </v-list-item>
                <!--!  Borrar la siguiente vez que toquemos el archivo👇👇👇👇👇👇👇👇👇
                 <v-list-item :href="manual" target="_blank">
                  <v-list-item-avatar>
                    <v-icon :dark="theme === 'dark'" class="">mdi-download</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-title>{{ $t('navbar.download_manual') }}</v-list-item-title>
                </v-list-item> -->
                <!-- ! ☝️☝️☝️☝️☝️☝️☝️☝️☝️☝️☝️-->
                <v-list-item @click="logOut">
                  <v-list-item-avatar>
                    <v-icon class="user-logout">mdi-logout-variant</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-title>{{ $t('nav_bar.menu.logout') }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </div>
      </v-row>
    </v-container>
  </v-app-bar>

  <!-- *Mobile version  -->

  <v-app-bar v-else app :color="navbar_color" id="header-navbar-mobile" light>
    <v-icon dense v-show="isMap || isTemp" class="option-left px-1" @click="showDeviceList">
      mdi-format-list-bulleted-square
    </v-icon>
    <v-tabs class="buttons-header buttons-header-mobile">
      <v-icon dense color="amber darken-2" class="px-3 ml-3" @click="temperatureView">mdi-thermometer-lines</v-icon>
      <div class="logo-version">
        <v-img
          to="/dashboard/temperatures"
          @click="reloadDevices"
          :src="logo"
          class="btn-logo btn-logo-mobile my-2"
          contain
          width="7rem">
          <!-- <span class="version-site version-site-mobile">{{release}}</span> -->
        </v-img>
      </div>

      <v-icon dense color="amber darken-2" class="px-3 ml-3" @click="mapView">mdi-map</v-icon>
    </v-tabs>

    <!-- Three Dot -->
    <v-menu :close-on-content-click="closeOnContentClick" offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn light icon v-bind="attrs" v-on="on">
          <!-- <v-icon>mdi-menu</v-icon> -->
          <v-icon class="mr-2"> mdi-dots-vertical </v-icon>
        </v-btn>
      </template>

      <v-list style="max-height: 550px; width: 15em" class="overflow-y-auto" id="menu-list-mobile">
        <v-list-item>
          <v-list-item-title class="subtitle-2 text-center">
            {{ username }}
          </v-list-item-title>
          <v-btn v-if="theme === 'dark'" class="px-3 ml-3" @click="changeTheme('light')" small>
            <v-icon fab small>mdi-white-balance-sunny</v-icon>
          </v-btn>

          <v-btn v-else class="px-3 ml-3" @click="changeTheme('dark')" small>
            <v-icon fab small>mdi-weather-night</v-icon>
          </v-btn>
        </v-list-item>
        <v-divider></v-divider>

        <v-list-item @click="mapView">
          <v-list-item-avatar>
            <v-icon>mdi-map</v-icon>
          </v-list-item-avatar>
          <v-list-item-title>{{ $t('nav_bar.menu.map') }}</v-list-item-title>
        </v-list-item>

        <v-list-item>
          <v-expansion-panels flat style="margin-left: -1.7em">
            <v-expansion-panel
              :style="{
                color: theme === 'dark' ? 'white' : '',
                backgroundColor: 'transparent !important',
              }"
              @click.stop="isExpansionOpen = true">
              <v-expansion-panel-header>
                <v-row style="margin-left: 1px" class="d-flex justify-space-around">
                  <v-col cols="4">
                    <v-icon>mdi-phone-message</v-icon>
                  </v-col>
                  <v-col cols="8">
                    <v-list-item-title> {{ $t('user.contact.support') }}</v-list-item-title>
                  </v-col>
                </v-row>
              </v-expansion-panel-header>
              <v-expansion-panel-content v-show="isExpansionOpen">
                <v-list-item>
                  <v-list-item-subtitle>
                    <v-icon :color="theme === 'dark' ? 'white' : 'rgba(0, 0, 0, 0.54)'" light>mdi-whatsapp</v-icon>
                  </v-list-item-subtitle>

                  <v-list-item-subtitle>
                    <a :href="waPhone"> {{ phoneSupport }}</a>
                  </v-list-item-subtitle>
                </v-list-item>
                <v-list-item>
                  <v-list-item-action>
                    <v-icon :color="theme === 'dark' ? 'white' : 'rgba(0, 0, 0, 0.54)'" light>mdi-email-fast</v-icon>
                  </v-list-item-action>
                  <v-list-item-subtitle>
                    <a :href="`mailto:${emailSupport}`">{{ emailSupport }}</a>
                  </v-list-item-subtitle>
                </v-list-item>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-list-item>

        <v-list-item @click="notificationsView">
          <v-list-item-avatar>
            <v-icon>mdi-bell</v-icon>
          </v-list-item-avatar>
          <v-list-item-title>{{ $t('nav_bar.menu.notifications') }}</v-list-item-title>
        </v-list-item>

        <v-list-item v-if="!isUserClient" @click="reportsView">
          <v-list-item-avatar>
            <v-icon>mdi-file-document</v-icon>
          </v-list-item-avatar>
          <v-list-item-title>{{ $t('nav_bar.menu.reports') }}</v-list-item-title>
        </v-list-item>

        <v-list-item v-if="!isUserClient" @click="certificatesView">
          <v-list-item-avatar>
            <v-icon>mdi-file-document</v-icon>
          </v-list-item-avatar>
          <v-list-item-title>{{ $t('dashboard.device_card.docs') }}</v-list-item-title>
        </v-list-item>

        <v-list-item v-if="!isUserClient" @click="programReportsView">
          <v-list-item-avatar>
            <v-icon>mdi-clipboard-clock-outline</v-icon>
          </v-list-item-avatar>
          <v-list-item-title> {{ $t('nav_bar.menu.programreports') }}</v-list-item-title>
        </v-list-item>

        <v-list-item @click="poiView">
          <v-list-item-avatar>
            <v-icon>mdi-map-marker-radius</v-icon>
          </v-list-item-avatar>
          <v-list-item-title>{{ $t('nav_bar.menu.poi') }}</v-list-item-title>
        </v-list-item>

        <v-list-item v-if="!isUserClient" @click="sharedView">
          <v-list-item-avatar>
            <v-icon>mdi-share-variant</v-icon>
          </v-list-item-avatar>
          <v-list-item-title>{{ $t('nav_bar.shared') }}</v-list-item-title>
        </v-list-item>

        <v-list-item v-if="!isUserClient" @click="clientsView">
          <v-list-item-avatar>
            <v-icon>mdi-account-multiple</v-icon>
          </v-list-item-avatar>
          <v-list-item-title>{{ $t('nav_bar.client') }}</v-list-item-title>
        </v-list-item>

        <v-list-item v-if="isUserAdmin" to="/admin">
          <v-list-item-avatar>
            <v-icon>mdi-cellphone-link</v-icon>
          </v-list-item-avatar>
          <v-list-item-title>{{ $t('nav_bar.admin') }} {{ $t('admin_menu.devices') }}</v-list-item-title>
        </v-list-item>

        <v-list-item v-if="isUserAdmin" to="/admin/users">
          <v-list-item-avatar>
            <v-icon>mdi-shield-account-variant</v-icon>
          </v-list-item-avatar>
          <v-list-item-title> {{ $t('nav_bar.admin') }} {{ $t('admin_menu.users') }}</v-list-item-title>
        </v-list-item>

        <v-list-item v-if="isUserAdmin" to="/admin/companies">
          <v-list-item-avatar>
            <v-icon>mdi-briefcase-account</v-icon>
          </v-list-item-avatar>
          <v-list-item-title> {{ $t('nav_bar.admin') }} {{ $t('company.list') }}</v-list-item-title>
        </v-list-item>

        <v-list-item v-if="isUserAdmin" to="/admin/beacons">
          <v-list-item-avatar>
            <v-icon>mdi-bluetooth</v-icon>
          </v-list-item-avatar>
          <v-list-item-title> {{ $t('nav_bar.admin') }} {{ $t('admin_menu.beacons') }}</v-list-item-title>
        </v-list-item>

        <v-list-item v-if="isUserAdmin" to="/admin/search">
          <v-list-item-avatar>
            <v-icon>mdi-archive-search-outline</v-icon>
          </v-list-item-avatar>
          <v-list-item-title> {{ $t('nav_bar.menu.search') }} </v-list-item-title>
        </v-list-item>

        <a class="text-decoration-none" :href="manual" :color="text_color" target="_blank">
          <v-list-item>
            <v-list-item-avatar>
              <v-icon>mdi-download</v-icon>
            </v-list-item-avatar>
            <v-list-item-title>{{ $t('navbar.download_manual') }}</v-list-item-title>
          </v-list-item>
        </a>

        <v-list-item @click="configurationView">
          <v-list-item-avatar>
            <v-icon>mdi-account-cog</v-icon>
          </v-list-item-avatar>
          <v-list-item-title>{{ $t('nav_bar.menu.configuration') }}</v-list-item-title>
        </v-list-item>

        <v-list-item @click="certificatesMaintenanceView">
          <v-list-item-avatar>
            <v-icon>mdi-wrench</v-icon>
          </v-list-item-avatar>
          <v-list-item-title>{{ $t('nav_bar.maintenance') }}</v-list-item-title>
        </v-list-item>

        <v-list-item @click="changePassword">
          <v-list-item-avatar>
            <v-icon>mdi-lock</v-icon>
          </v-list-item-avatar>
          <v-list-item-title>{{ $t('user.actions.change_password') }}</v-list-item-title>
        </v-list-item>

        <v-list-item @click="validateNotificationView">
          <v-list-item-avatar>
            <v-icon>mdi-email</v-icon>
          </v-list-item-avatar>
          <v-list-item-title>{{ $t('navbar.notifications_settings') }}</v-list-item-title>
        </v-list-item>

        <v-list-item>
          <v-expansion-panels flat style="margin-left: -1.7em">
            <v-expansion-panel
              :style="{
                color: theme === 'dark' ? 'white' : '',
                backgroundColor: 'transparent !important',
              }"
              @click.stop="isExpansionOpen = true">
              <v-expansion-panel-header>
                <v-row style="margin-left: 1px" class="d-flex justify-space-around">
                  <v-col cols="4">
                    <v-icon>mdi-web</v-icon>
                  </v-col>
                  <v-col cols="8">
                    <v-list-item-title> {{ $t('nav_bar.menu.languages') }}</v-list-item-title>
                  </v-col>
                </v-row>
              </v-expansion-panel-header>
              <v-expansion-panel-content v-show="isExpansionOpen">
                <v-list :style="{ 'background-color': 'transparent !important' }">
                  <v-list-item v-for="language in languages" @click="changeLanguage(language.code)" v-bind:key="language.code">
                    <v-list-item-avatar>
                      <country-flag :country="language.icon_code" />
                    </v-list-item-avatar>

                    <v-list-item-title style="font-size: small">{{ language.name }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-list-item>

        <v-divider></v-divider>

        <v-list-item @click="logOut">
          <v-list-item-avatar>
            <v-icon>mdi-logout-variant</v-icon>
          </v-list-item-avatar>
          <v-list-item-title>{{ $t('nav_bar.menu.logout') }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<style>
.cursor {
  cursor: pointer;
}

.tab {
  width: 10rem;
  background-color: black;
}
</style>

<script>
import router from '../../plugins/router/index';
import userViewModel, { getImage } from '../../viewModels/userViewModel';
import devicesViewModel from '../../viewModels/devicesViewModel';
import adminViewModel from '../../viewModels/devicesViewModel';
import constants from '../../plugins/lang/constants';
import { getUpdatedNotifications } from '../../server/petitionsMqtt/notifications/updateNotifications';
import { getUser } from '../../viewModels/userViewModel';
import clientViewModel from '../../viewModels/clientViewModel';
import { eventBus } from '../../main';
const ls = require('localstorage-slim');

export default {
  components: {},
  props: {
    device: {
      type: Object,
      default: () => ({ device_sn: null }),
    },
    carplate: {},
    routeLoading: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    isExpansionOpen: false,
    theme: ls.get('theme') ?? 'ligth',
    menu: false,
    closeOnContentClick: true,
    languages: constants.locales,
    isUserAdmin: false,
    isUserClient: false,
    username: '',
    newNotifications: false,
    lang: '',
    darkMode: false,
    manual: process.env.VUE_APP_MANUAL,
    logo: require(process.env.VUE_APP_LOGO),
    navbar_color: process.env.VUE_APP_NAVBAR_COLOR,
    width_logo: process.env.VUE_APP_WIDTH_LOGO,
    text_color: process.env.VUE_APP_TEXT_COLOR,
    icon_color: process.env.VUE_APP_ICON_COLOR,
    messages: 0,
    resImage: null,
    bottomSheetVisible: false,
    release: process.env.VUE_APP_RELEASE,
    waPhone: process.env.VUE_APP_WA_PHONE,
    phoneSupport: process.env.VUE_APP_PHONE,
    emailSupport: process.env.VUE_APP_EMAIL_SUPPORT,
  }),
  methods: {
    logOut: async function () {
      await userViewModel.logout();
      await devicesViewModel.reset();
      await adminViewModel.reset();
      await clientViewModel.reset();
      await ls.clear();
      eventBus.$emit('logout');
      router.push({ path: 'login' });
    },

    changeLanguage: async function (code) {
      let user = await userViewModel.getUser();
      let email = user.email;
      userViewModel.changeLanguage(this, email, code);
      this.lang = code;
      eventBus.$emit('changeLanguageEvent', this.lang);
    },

    temperatureView() {
      eventBus.$emit('cleanSearch');
      // eventBus.$emit('reloadDevices'); //COMENTADO PORQUE DUPLICA DISPOSITIVOS EN EL MAPA

      if (this.currentRouteName != 'temperatures') {
        router.push({
          name: 'temperatures',
          params: { device: this.$store.state.selectedDevice },
        });
      }
      if (this.$store.state.selectedDevice != null && this.$store.state.selectedDevice.device_sn) {
        eventBus.$emit('focusOnThisDevice', null);
      }
      eventBus.$emit('closeDeviceList');
    },

    reloadDevices() {
      eventBus.$emit('cleanSearch');

      eventBus.$emit('cleanMarkerList');

      this.$store.dispatch('setSelectedDevice', null);

      eventBus.$emit('reloadDevices');

      if (this.currentRouteName != 'map' && this.currentRouteName != 'temperatures') {
        router
          .push({
            name: 'map',
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        eventBus.$emit('reloadMapCorrectly');
      }
    },

    mapView() {
      // EMITS COMENTADOS PORQUE DUPLICAN DISPOSITIVOS EN EL MAPA
      // eventBus.$emit('cleanSearch');
      // eventBus.$emit('reloadDevices');
      if (this.currentRouteName != 'map') {
        router.push({
          name: 'map',
          params: { device: this.device },
        });
      } else {
        eventBus.$emit('reloadMapCorrectly');
      }
      if (this.$store.state.selectedDevice) {
        // eventBus.$emit('focusOnThisDevice', null);
        eventBus.$emit('focusOnThisDevice', this.$store.state.selectedDevice);
      }
      /*else { // Se hacía un emit para centrar dispositivos en 'X' coordenadas
        eventBus.$emit('zoomMap');
      }*/

      // No le llega ningun serial number (null) entonces no sigue el if
      // if (this.$store.state.selectedDevice.device_sn) {
      //   eventBus.$emit('focusOnThisDevice', null);
      // } else {
      //   eventBus.$emit('zoomMap', null);
      // }
      eventBus.$emit('closeDeviceList', this.$store?.state?.selectedDevice);
    },

    tempView() {
      router.push({
        name: 'temperatures',
        params: { device: this.device },
      });
      // eventBus.$emit("zoomMap", null);
    },

    certificatesMaintenanceView: function () {
      router.push({ name: 'cert' });
    },

    configurationView: function () {
      router.push({ name: 'configuration' });
    },

    validateNotificationView: function () {
      router.push({ name: 'emails' });
    },

    dashboardView: function () {
      eventBus.$emit('zoomMap', null);
      router.push({ name: 'dashboard' });
    },

    reportsView: function () {
      router.push({ name: 'reports' });
    },

    certificatesView: function () {
      router.push({ name: 'docs' });
    },

    programReportsView: function () {
      router.push({ name: 'programReport' });
    },

    notificationsView: function () {
      router.push({ name: 'notifications' });
    },

    sharedView: function () {
      router.push({ name: 'shared' });
    },

    poiView: function () {
      router.push({ name: 'pois' });
    },

    adminView: function () {
      router.push({ name: 'admin' });
    },

    changeTheme(theme) {
      this.darkMode = !this.darkMode;
      eventBus.$emit('changeTheme', theme);
    },

    clientsView: function () {
      router.push({ name: 'shareCustomer' });
    },

    showDeviceList: function () {
      this.$emit('showDeviceList');
    },

    setMapZoom() {
      eventBus.$emit('zoomMap', null);
    },

    changePassword() {
      router.push({ name: 'password' });
    },
  },

  mounted: async function () {
    eventBus.$on('changeTheme', (theme) => {
      this.theme = theme;
    });
    let user = await userViewModel.getUser();
    this.resImage = await getImage(user.email);
    this.lang = user.locale;
    this.username = user.name;
    if (user.type == 'admin') {
      this.isUserAdmin = true;
    }
    if (user.type == 'client') {
      this.isUserClient = true;
    }
    this.getTable;
    this.suscribeToNotifications;
  },

  computed: {
    currentRouteName() {
      return this.$route.name;
    },
    suscribeToNotifications: async function () {
      this.user = await getUser();
      let isSuscribed = false;
      getUpdatedNotifications(this.user.email, isSuscribed, (notification) => {
        this.newNotifications = true;
      });
    },

    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },
    isMap() {
      return this.$route.name == 'map';
    },
    isTemp() {
      return this.$route.name == 'temperatures';
    },
  },
};
</script>
