<template>
  <v-container v-if="!isMobile" class="shared-container-desktop pa-2">
    <v-row class="shared-content">
      <v-col cols="2" class="menu-shared">
        <v-navigation-drawer permanent class="panel-left-shared">
          <v-toolbar-title class="mb-5" style="margin-top: 3rem; margin-left: 0.5em"></v-toolbar-title>

          <v-list-item-content>
            <v-list-item-title
              class="text-h6"
              style="text-align: center; font-size: medium !important"
              :style="{
                color: theme === 'dark' ? 'white ' : 'black',
              }">
              {{ $t('sideBar.user_settings') }}
            </v-list-item-title>
          </v-list-item-content>
          <v-divider></v-divider>
          <v-tabs vertical class="tab-vertical" center-active>
            <v-list>
              <v-tab to="/configuration/user" class="panel-left-btn d-flex justify-space-between">
                <v-icon>mdi-account</v-icon>
                <span>{{ $t('sideBar.user') }}</span>
                <v-icon>mdi-chevron-right</v-icon>
              </v-tab>
              <v-divider></v-divider>
              <v-tab to="/configuration/password" class="panel-left-btn d-flex justify-space-between">
                <v-icon>mdi-lock</v-icon>
                <span>{{ $t('sideBar.password') }}</span
                ><v-icon>mdi-chevron-right</v-icon>
              </v-tab>
              <v-divider></v-divider>
              <v-tab to="/configuration/metrics" class="panel-left-btn d-flex justify-space-between">
                <v-icon>mdi-calculator</v-icon>
                <span>{{ $t('sideBar.unit_measurement') }}</span
                ><v-icon>mdi-chevron-right</v-icon>
              </v-tab>
              <v-divider></v-divider>
              <v-tab to="/configuration/emails" class="panel-left-btn d-flex justify-space-between">
                <v-icon>mdi-email</v-icon>
                <span>{{ $t('sideBar.notifications') }}</span>
                <v-icon>mdi-chevron-right</v-icon>
              </v-tab>
            </v-list>
          </v-tabs>
          <v-divider></v-divider>

          <v-list-item-content>
            <v-list-item-title
              class="text-h6"
              style="text-align: center; font-size: medium !important"
              :style="{
                color: theme === 'dark' ? 'white ' : 'black',
              }">
              {{ $t('sideBar.others') }}
            </v-list-item-title>
          </v-list-item-content>
          <v-divider></v-divider>
          <v-tabs vertical class="tab-vertical">
            <v-list>
              <v-tab to="/configuration/alarms" class="panel-left-btn d-flex justify-space-between">
                <v-icon>mdi-alarm</v-icon>
                <span>{{ $t('sideBar.alarms') }}</span
                ><v-icon>mdi-chevron-right</v-icon>
              </v-tab>
              <v-divider></v-divider>
              <v-tab to="/configuration/calendar" class="panel-left-btn d-flex justify-space-between">
                <v-icon>mdi-calendar</v-icon>
                <span>{{ $t('sideBar.calendar') }}</span
                ><v-icon>mdi-chevron-right</v-icon>
              </v-tab>
              <v-tab to="/maintenance" class="panel-left-btn d-flex justify-space-between">
                <v-icon>mdi-wrench</v-icon>
                <span>{{ $t('navbar.maintenance') }}</span
                ><v-icon>mdi-chevron-right</v-icon>
              </v-tab>
              <v-divider></v-divider>
              <v-tab to="/configuration/names" class="panel-left-btn d-flex justify-space-between">
                <v-icon>mdi-tag</v-icon>
                <span>{{ $t('sideBar.names') }}</span
                ><v-icon>mdi-chevron-right</v-icon>
              </v-tab>
              <v-divider></v-divider>
              <v-tab
                enabled
                v-if="user?.type !== 'client'"
                to="/configuration/docs"
                class="panel-left-btn d-flex justify-space-between">
                <v-icon>mdi-text-box-multiple-outline</v-icon>
                <span>{{ $t('sideBar.docs') }}</span
                ><v-icon>mdi-chevron-right</v-icon>
              </v-tab>
              <v-divider></v-divider>
              <!-- <v-tab to="/configuration/beacons" class="panel-left-btn d-flex justify-space-between">
                <v-icon>mdi-bluetooth</v-icon>
                <span>{{ $t('admin.menu.beacons') }}</span
                ><v-icon>mdi-chevron-right</v-icon>
              </v-tab> -->
            </v-list>
          </v-tabs>
        </v-navigation-drawer>
      </v-col>
      <v-col cols="10" class="shared-table">
        <router-view style="z-index: -1"></router-view>
      </v-col>
    </v-row>
  </v-container>
  <v-container v-else class="shared-container-mobile pa-2 ma-2" style="max-width: 2500px">
    <router-view style="z-index: -1" class="shared-content-mobile"></router-view>
  </v-container>
</template>

<script>
import { getUser } from '../../viewModels/userViewModel';
const ls = require('localstorage-slim');
import { eventBus } from '../../main';

export default {
  props: {
    device: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    theme: ls.get('theme') ?? 'ligth',
    user: null,
  }),
  methods: {},
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },
  },
  mounted: async function (e) {
    this.user = await getUser();
    eventBus.$on('changeTheme', (theme) => {
      this.theme = theme;
    });
  },
};
</script>
