<template>
  <v-dialog
    :dark="theme === 'dark'"
    v-model="isActive"
    id="shared-device-vdialogo"
    max-width="50rem"
    @click:outside="cancelShare"
    style="overflow-y: hidden"
    @keydown.esc="cancelShare">
    <v-card>
      <v-card-title>
        <v-form v-model="formValid" ref="form" lazy-validation style="width: 100%">
          <v-container>
            <v-alert outlined color="error" icon="warning" :value="errorSummary">{{ errorSummary }}</v-alert>

            <v-row dense>
              <v-col>
                <v-select
                  dense
                  :label="this.$t('shared.select_one')"
                  autocomplete
                  multiple
                  outlined
                  required
                  :items="devicesAlias"
                  item-text="text"
                  item-value="value"
                  v-model="selectedDevices" />
              </v-col>
              <v-col>
                <v-select
                  dense
                  :label="this.$t('shared.select_who')"
                  autocomplete
                  outlined
                  required
                  :items="customersEmails"
                  v-model="selectedCustomer" />
              </v-col>
            </v-row>

            <v-row dense style="justify-content: center">
              <v-col cols="8">
                <v-text-field
                  :disabled="checkbox"
                  class="centered-input text--darken-3 mt-3"
                  dense
                  outlined
                  :label="this.$t('shared.select_time')"
                  v-model="selectedTime"
                  required
                  :rules="[(v) => v > 0]">
                  <template v-slot:append>
                    <v-select
                      :disabled="checkbox"
                      :items="selectedTimeUnitOptions"
                      item-text="text"
                      item-value="value"
                      outlined
                      v-model="selectedTimeUnit"
                      label="Select"
                      dense
                      solo />
                  </template>
                </v-text-field>
              </v-col>
              <v-col cols="4">
                <v-checkbox class="ml-2" v-model="checkbox" :label="$t('shared.always_shared')"></v-checkbox>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-title>
      <v-card-actions>
        <v-row>
          <v-col style="text-align: right">
            <v-btn @click="share" color="primary" :disabled="selectedDevices.length == 0 || selectedCustomer == null">
              <v-icon class="mr-1">mdi-share</v-icon> {{ $t('shared.share_device') }}
            </v-btn>
          </v-col>
          <v-col>
            <v-btn @click="cancelShare" color="red">
              <v-icon class="white--text mr-1">mdi-exit-to-app</v-icon>
              <span class="white--text">{{ $t('general.form.exit') }}</span>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<style>
.v-dialog {
  overflow: hidden;
}
.v-text-field .v-label {
  overflow: visible;
}
.centered-input input {
  text-align: center;
}
</style>

<script>
import { getUserToShareDevices } from '../../../viewModels/userViewModel';
import { shareDevice } from '../../../viewModels/devicesViewModel';
import { getCustomers } from '../../../viewModels/clientViewModel';
import { getUserDevices } from '../../../server/petitions/users/getUserDevices';
import { eventBus } from '../../../main';
const ls = require('localstorage-slim');

export default {
  components: {},
  props: {
    isActive: {
      type: Boolean,
      default: false,
    },
    devicesTable: {
      type: Array,
      default: [],
    },
  },
  data: () => ({
    theme: ls.get('theme') ?? 'ligth',
    user: null,
    devices: [],
    customers: [],
    formValid: true,
    selectedDevices: [],
    selectedCustomer: null,
    errorSummary: false,
    selectedTime: 1,
    selectedTimeUnit: 'Y',
    checkbox: false,
    alwaysShared: '2099-12-31T23:59:59.000+0000',
  }),
  computed: {
    devicesAlias() {
      return this.devices
        .filter((device) => device.type === 'owned_device')
        .map((device) => ({ text: device.alias, value: `${device.device_sn} - ${device.alias}` }));
    },
    customersEmails() {
      return this.customers.map((customer) => customer.email);
    },
    selectedTimeUnitOptions() {
      return [
        { text: this.selectedTime > 1 ? this.$t('shared.days') : this.$t('shared.day'), value: 'D' },
        { text: this.selectedTime > 1 ? this.$t('shared.months') : this.$t('shared.month'), value: 'M' },
        { text: this.selectedTime > 1 ? this.$t('shared.years') : this.$t('shared.year'), value: 'Y' },
      ];
    },
  },

  methods: {
    cancelShare: function () {
      this.selectedDevices = [];
      this.selectedCustomer = null;
      this.errorSummary = false;
      this.selectedTime = 1;
      this.selectedTimeUnit = 'Y';
      this.checkbox = false;
      this.getUserAndDevices();
      this.$emit('cancel-share');
    },
    getUserAndDevices: async function () {
      this.user = await getUserToShareDevices();
      this.devices = await getUserDevices(this.user.token, this.user.userId);
      console.log('🚀 ~ this.devices:', this.devices);
      this.customers = await getCustomers();
    },

    share: async function () {
      let sharedTime;
      if (this.checkbox) {
        sharedTime = new Date(this.alwaysShared);
      } else {
        sharedTime = this.addDate(new Date(), this.selectedTime, this.selectedTimeUnit);
      }

      console.log('🚀 ~ payload.this.selectedDevices:', this.selectedDevices);
      let payload = {
        selected: this.selectedDevices,
        client: this.selectedCustomer,
        time: sharedTime,
        user: this.user,
      };

      let shareDeviceResult = await shareDevice(payload);

      this.$emit('result-share', shareDeviceResult);
      this.cancelShare();
    },

    addDate(oldDate, offset, offsetType) {
      const year = oldDate.getFullYear();
      const month = oldDate.getMonth();
      const date = oldDate.getDate();
      const hour = oldDate.getHours();
      const minutes = oldDate.getMinutes();

      const offsetTypeUpperCase = offsetType.toUpperCase();

      const offsetOperations = {
        Y: () => new Date(year + parseInt(offset), month, date, hour, minutes),
        M: () => new Date(year, month + parseInt(offset), date, hour, minutes),
        D: () => new Date(year, month, date + parseInt(offset), hour, minutes),
        H: () => new Date(year, month, date, hour + parseInt(offset), minutes),
      };

      if (!offsetOperations[offsetTypeUpperCase]) {
        throw new Error(`Invalid offsetType: ${offsetType}`);
      }

      return offsetOperations[offsetTypeUpperCase]();
    },
  },
  mounted: async function () {
    eventBus.$on('changeTheme', (theme) => {
      this.theme = theme;
    });
    eventBus.$on('sharedDeviceDeleted', () => {
      this.getUserAndDevices();
    });

    this.getUserAndDevices();
  },
};
</script>
