<template>
  <div>
    <Navbar @showDeviceList="showDeviceList"></Navbar>
    <div v-if="firstTime"><ChangePassword /></div>
    <div v-if="restricted"><UserRestricted /></div>
    <UpdatesDialog :isActive="updatesDialog" v-on:accept-dialog="acceptDialog"> </UpdatesDialog>
    <v-main id="container-general-dashboard" style="overflow: hidden">
      <router-view ref="dashboard"></router-view>
      <Footer></Footer>
    </v-main>
  </div>
</template>

<script>
import ChangePassword from './views/changePassword.vue';
import UserRestricted from './views/userRestricted.vue';
import Navbar from './components/navBar/navbar.vue';
import userviewModel from './viewModels/userViewModel';
import BrokerConnection from './server/brokerConnection/brokerConnection';
import UpdatesDialog from './components/updatesDialog.vue';
import Footer from './components/footer/footer.vue';

export default {
  name: 'App',
  components: {
    Navbar,
    ChangePassword,
    UserRestricted,
    UpdatesDialog,
    Footer,
  },
  data: () => ({
    firstTime: false,
    updatesDialog: false,
    restricted: false,
  }),
  methods: {
    setUserDefaultLanguage: function () {
      userviewModel.setLanguageToUserDefault(this);
    },
    showDeviceList: function () {
      this.$refs.dashboard.showDeviceList();
    },
    initializeBrokerConnection: async function () {
      await BrokerConnection.initializeBroker();
    },

    acceptDialog() {
      this.updatesDialog = false;
      userviewModel.markUpdateSeen();
    },
  },

  async mounted() {
    const user = await userviewModel.getUser();
    if (user.firstTime) {
      this.firstTime = true;
      this.updatesDialog = false;
    }

    if (user.restricted) {
      this.restricted = true;
    } else {
      this.updatesDialog = user.update_seen ? false : true;
    }
    //
    this.setUserDefaultLanguage();
    //this.initializeBrokerConnection();
  },
};
</script>

<style></style>
